import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import all from './all'
import auth from './auth'
import client from './client'
import deal from './deal'
import admin from './admin'
import workspace from './workspace'

Vue.use(Router)

const checkAuthentication = async () => {
  const isAuthed = store.state.isUserAuthenticated

  // If isAuthed === undefined, user is in an unknown state and has not been loaded

  if (isAuthed !== false) {
    if (!store.state.User.user.id) {
      // Need to load the authenticated user into the state
      try {
        await store.dispatch('getUser')

        // Refresh their auth token as well so they don't get logged out
        // inside this session
        await store.dispatch('refreshAuthToken')

        return true
      } catch (error) {
        if (isAuthed) {
          // The "authenticated" user doesn't really exist
          await store.dispatch('logout')
        } else {
          // User was never authenticated/loaded
          store.dispatch('setUserUnauthenticated')
        }
      }
    } else {
      // User is already authenticated and loaded
      return true
    }
  }

  // User is not authenticated
  return false
}

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    ...auth,
    ...deal,
    ...client,
    ...admin,
    ...workspace,
    ...all
  ]
})

const isIE = navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1
let isUserIdAnalyticsSet = false

router.beforeEach(async (to, from, next) => {
  if (isIE) {
    if (to.name === 'UnsupportedBrowser') return next()
    return next('/unsupported-browser')
  }

  // Check if "io_redirected" is in query string
  // If so add it to the state and remove it from query string
  if (to.query && to.query.io_redirect) {
    store.commit('SET_IO_REDIRECTED', true)

    const query = { ...to.query }
    delete query.io_redirect
    return next({
      path: to.path,
      query,
      replace: true
    })
  }

  const authNotRequired = to.matched.some((route) => route.meta.noAuth)
  const permissionRequired = to.matched.some((route) => route.meta.permRequired)
  const isAuthed = await checkAuthentication()
  const twoFactorValidationRequired = store.state['2faValidationRequired']
  const twoFactorEnrollmentRequired = store.state['2faEnrollmentRequired']
  const hasCompanyGroup = isAuthed && store.state.User.user.companyGroup
  const productConfigRoutes = ['AdminProductDetails', 'AdminTacticsAndOptions', 'AdminCollateralAndDocuments', 'AdminSubmissions', 'AdminRecommendationSettings', 'AdminExternalMappings']

  if (isAuthed && !isUserIdAnalyticsSet && router.app.$ga) {
    const analyticsData = {
      userId: store.state.User.user.id
    }

    if (hasCompanyGroup) {
      analyticsData.dimension1 = store.state.User.user.companyGroup.name
    }

    router.app.$ga.set(analyticsData)
    isUserIdAnalyticsSet = true
  }

  if ((from.name === 'AdminAudiencePreset' || from.name === 'AdminAudienceConfiguration') &&
    (to.name !== 'AdminAudiencePreset' && to.name !== 'AdminAudienceConfiguration')) {
    store.state.selectedCompanyForAudience = null
  }

  if (productConfigRoutes.includes(from.name) && !productConfigRoutes.includes(to.name)) {
    store.state.selectedProductName = null
  } // prevent product name from flashing old selected product name

  if (store.state.unSavedValue && (from.name === 'AdminAudiencePreset' || from.name === 'AdminAudienceConfiguration')) {
    store.state.showUnsavedModal = true
    store.state.toNextRoute = to.name
  } else if (!authNotRequired && !isAuthed) {
    next({
      name: 'Login',
      query: {
        path: to.fullPath
      }
    })
  } else if (to.name === 'Logout') {
    next()
  } else if (
    isAuthed &&
    store.state.User.user.showInitialSetup
  ) {
    if (!to.name.startsWith('InitialSetup')) next({ name: 'InitialSetup', query: { path: to.fullPath } })
    else next()
  } else if (
    isAuthed &&
    store.state.User.user.mustResetPassword &&
    store.state.User.user.passwordResetAllowed
  ) {
    if (to.name !== 'MustResetPassword') next({ name: 'MustResetPassword', query: { path: to.fullPath } })
    else next()
  } else if (
    twoFactorEnrollmentRequired
  ) {
    if (to.name !== 'EnrollTwoFactor') next({ name: 'EnrollTwoFactor', query: { path: to.fullPath } })
    else next()
  } else if (
    twoFactorValidationRequired
  ) {
    if (to.name !== 'ValidateTwoFactor') next({ name: 'ValidateTwoFactor', query: { path: to.fullPath } })
    else next()
  } else if (
    isAuthed &&
    !hasCompanyGroup
  ) {
    if (to.name !== 'SwitchGroup') next({ name: 'SwitchGroup', query: { path: to.fullPath } })
    else next()
  } else if (permissionRequired) {
    if (Vue.permission.hasRoute(to.name)) next()
    else {
      next({ name: 'Forbidden' })
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  Vue.socket.emit('session:page-view', {
    path: to.fullPath,
    time: new Date().getTime()
  })
})

router.onError(error => {
  if (/loading.*chunk .* failed./i.test(error.message)) {
    store.commit('ADD_OUTDATED_ERROR_MESSAGE')
  }
})

export default router
