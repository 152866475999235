import Vue from 'vue'

function list (params = {}) {
  const path = '/products'
  return Vue.api.get(path, { params })
}

export default {
  list
}
